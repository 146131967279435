import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetSupplementalProductsDocument,
  GetSupplementalProductsQuery,
  GetSupplementalProductsQueryVariables,
  LineItemDataFragment,
  ProductAvailabilityDataFragment,
  SupplementalProductVariantDataFragment,
} from 'generated/api/graphql';
import { last } from 'lodash';
import * as Sentry from '@sentry/nextjs';

type ProductUrlArgs = Pick<
  Sproutl.ProductVariant,
  'sku' | 'slug' | 'categories' | 'attributesRaw'
>;

type SuggestedVariant = SupplementalProductVariantDataFragment & {
  bestOffer: Sproutl.ProductOffer | null;
};

export function getProductUrl({
  sku,
  slug,
  categories,
  attributesRaw,
}: ProductUrlArgs): string {
  let categorySlug = 'products';

  if (categories && Array.isArray(categories)) {
    categorySlug = last(categories)?.slug || categorySlug;
  }

  if (attributesRaw) {
    categorySlug =
      attributesRaw.find(({ name }) => name === 'category_slug')?.value ||
      categorySlug;
  }

  return `/${categorySlug}${categorySlug ? '/' : ''}${slug}-${sku}`;
}

export function getOffers(
  variant: ProductAvailabilityDataFragment,
): Sproutl.ProductOffer[] {
  const offers: Sproutl.ProductOffer[] = [];

  variant?.availability?.channels.results.forEach((channel) => {
    const price = variant?.prices?.find(
      (priceOption) => priceOption?.channel?.key === channel?.channel?.key,
    );

    if (channel.channel?.name && channel.channel.key && price) {
      offers.push({
        partner: {
          name: channel.channel.name,
          slug: channel.channel.key,
        },
        price: price.value,
        isOnStock: channel.availability.isOnStock,
        channel: channel.channel,
        availableQuantity: channel.availability.availableQuantity,
      });
    }
  });

  return offers;
}

export function getSupplementalProductVariantOffers(
  variant: SupplementalProductVariantDataFragment,
): Sproutl.ProductOffer[] {
  const offers: Sproutl.ProductOffer[] = [];

  variant?.availability?.channels.results.forEach((channel) => {
    const price = variant?.prices?.find(
      (priceOption) => priceOption?.channel?.key === channel?.channel?.key,
    );

    if (channel.channel?.name && channel.channel.key && price) {
      offers.push({
        partner: {
          name: channel.channel.name,
          slug: channel.channel.key,
        },
        price: price.value,
        isOnStock: channel.availability.isOnStock,
        channel: channel.channel,
        availableQuantity: channel.availability.availableQuantity,
      });
    }
  });

  return offers;
}

export function sortOffersByBestPrice(
  a: Sproutl.ProductOffer,
  b: Sproutl.ProductOffer,
) {
  const centAmount = a.price.centAmount - b.price.centAmount;

  if (centAmount === 0) {
    return b.availableQuantity - a.availableQuantity;
  }

  return centAmount;
}

export function pickBestOffer(commerce: ProductAvailabilityDataFragment) {
  const offers = getOffers(commerce).sort(sortOffersByBestPrice);
  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers.length
    ? onStockOffers[0]
    : offers.length
    ? offers[0]
    : null;
}

/*
 * TODO: logic optimisation here; ability to specify a preferred channel (the channel of the main product),
 * to reduce the chance of a user getting a split-channel basket
 */
export function pickBestSupplementalProductVariantOffer(
  variant: SupplementalProductVariantDataFragment,
): Sproutl.ProductOffer | null {
  const offers = getSupplementalProductVariantOffers(variant).sort(
    sortOffersByBestPrice,
  );
  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers.length
    ? onStockOffers[0]
    : offers.length
    ? offers[0]
    : null;
}

export async function mapSuggestedSupplementalProducts(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  suggestedPots: Sproutl.ProductVariant[] = [],
): Promise<Sproutl.ProductVariant[]> {
  if (!suggestedPots) {
    return [];
  }

  // Create array of suggested skus
  const suggestedSkus = suggestedPots
    .map((suggestedPot) => suggestedPot && suggestedPot.sku)
    .filter((sku) => sku);

  if (!suggestedSkus?.length) {
    return [];
  }

  try {
    const suggestedProducts = await apolloClient.query<
      GetSupplementalProductsQuery,
      GetSupplementalProductsQueryVariables
    >({
      query: GetSupplementalProductsDocument,
      variables: {
        skus: suggestedSkus,
      },
    });

    const suggestedVariants: Record<string, SuggestedVariant> =
      suggestedProducts.data.products.results
        // Group all the variants together into one array
        .flatMap(
          (suggestedProducts) =>
            suggestedProducts.masterData.current?.allVariants,
        )
        // Add best offer information to each variant
        .map((suggestedVariant) => ({
          ...suggestedVariant,
          bestOffer: suggestedVariant
            ? pickBestSupplementalProductVariantOffer(suggestedVariant)
            : null,
        }))
        // Transform into an object with the variant SKU as the key
        .reduce((acc, suggestedVariant) => {
          if (!suggestedVariant || !suggestedVariant.sku) return acc;

          return {
            ...acc,
            [suggestedVariant.sku]: suggestedVariant,
          };
        }, {});

    return (
      suggestedPots
        // Filter out falsy suggestedPots and ones without a sku
        .filter((suggestedPot) => suggestedPot && suggestedPot.sku)
        // Add suggested variant data to pot
        .map((suggestedPot) => ({
          ...suggestedVariants[suggestedPot.sku],
          ...suggestedPot,
        }))
        // Remove any out of stock suggestedPots
        .filter(({ bestOffer }) => bestOffer && bestOffer.isOnStock)
    );
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}

export function getStockAvailabilityFromItem(item: LineItemDataFragment) {
  const channelKey = item.distributionChannel?.key;

  const itemAvailability = item.variant?.availability?.channels.results.find(
    (prodItem) => prodItem.channel?.key === channelKey,
  );

  return (
    itemAvailability?.availability || {
      isOnStock: false,
      availableQuantity: 0,
    }
  );
}
