import { getProductUrl, pickBestOffer } from 'utils/commercetools/product';
import { ProductListingDataFragmentWithSelectedVariant } from 'utils/types';
import {
  GroupedFeatureMetadata,
  ProductProjectionWithSelectedVariant,
  ProductVariant,
} from './types';

function getOffers(variant: ProductVariant): Sproutl.ProductOffer[] {
  const offers: Sproutl.ProductOffer[] = [];

  Object.entries(variant?.availability?.channels || {}).forEach(
    ([channelId, channelVal]) => {
      const price = (variant?.prices || []).find(
        (priceOption) => priceOption?.channel?.id === channelId,
      );

      if (channelVal && price) {
        offers.push({
          price: price.value,
          isOnStock: channelVal.isOnStock,
          availableQuantity: channelVal.availableQuantity,
        });
      }
    },
  );

  return offers;
}

function sortOffersByBestPrice(
  a: Sproutl.ProductOffer,
  b: Sproutl.ProductOffer,
) {
  return a.price.centAmount - b.price.centAmount;
}

function pickProjectionBestOffer(product: ProductVariant) {
  const offers = getOffers(product).sort(sortOffersByBestPrice);
  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers.length
    ? onStockOffers[0]
    : offers.length
    ? offers[0]
    : null;
}

export function mapGraphQLProductToInterface(
  product: ProductListingDataFragmentWithSelectedVariant,
): Sproutl.ProductListing {
  const bestOffer = pickBestOffer(product.selectedVariant);

  const { price = null, isOnStock = false, channel = null } = bestOffer || {};

  const selectedVariantName = product.selectedVariant.attributesRaw.find(
    (attr: { name: string }) => attr.name === 'name_variant',
  )?.value;

  return {
    price,
    isOnStock,
    channel,
    name: selectedVariantName || product.name || '',
    sku: product.selectedVariant.sku || '',
    slug: product.selectedVariant.key || '',
    photo: product.selectedVariant.images?.[0]
      ? { secure_url: product.selectedVariant.images[0]?.url }
      : null,
    attributesRaw: product.selectedVariant.attributesRaw,
  };
}

export function mapProductProjectionToInterface(
  results: ProductProjectionWithSelectedVariant[],
  groupedFeatureMetadata: GroupedFeatureMetadata,
): Sproutl.ProductListing[] {
  return results.reduce((acc, product) => {
    const bestOffer = pickProjectionBestOffer(product.selectedVariant);

    const { price = null, isOnStock = false } = bestOffer || {};

    const selectedVariantName = product.selectedVariant.attributes.find(
      (attr: { name: string }) => attr.name === 'name_variant',
    )?.value;

    const allVariants = [product.masterVariant, ...product.variants];

    const variantPrices = allVariants.reduce((acc, variant) => {
      const bestVariantOffer = pickProjectionBestOffer(variant);

      if (bestVariantOffer) {
        acc.push(bestVariantOffer.price.centAmount);
      }

      return acc;
    }, [] as number[]);

    const variantOnStockPrices = allVariants.reduce((acc, variant) => {
      const bestVariantOffer = pickProjectionBestOffer(variant);

      if (bestVariantOffer && bestVariantOffer.isOnStock) {
        acc.push(bestVariantOffer.price.centAmount);
      }

      return acc;
    }, [] as number[]);

    const selectedVariantColor = product.selectedVariant.attributes.find(
      (attr) => attr.name === 'colour',
    );

    const variantColors = allVariants.reduce((acc, variant) => {
      const color = variant.attributes.find((attr) => attr.name === 'colour');
      if (color) {
        const colorHex =
          groupedFeatureMetadata?.colour?.variants?.find(
            (colourVariant) => colourVariant.label === color.value.label,
          )?.color || color.value.key;

        if (!acc.find((currentColor) => currentColor.color === colorHex)) {
          const isSelected = selectedVariantColor
            ? selectedVariantColor.value.key === color.value.key
            : false;

          const method = isSelected ? 'unshift' : 'push';

          acc[method]({
            color: colorHex,
            isSelected,
            url: getProductUrl({
              sku: variant.sku,
              slug: variant.key,
              attributesRaw: variant.attributes,
            }),
          });
        }
      }

      return acc;
    }, [] as Sproutl.IColorProps[]);

    const minPrice = Math.min(...variantPrices);
    const maxPrice = Math.max(...variantPrices);

    const minOnStockPrice = Math.min(...variantOnStockPrices);

    const fromPrice =
      minPrice && minOnStockPrice && maxPrice && maxPrice > minPrice
        ? { centAmount: minOnStockPrice, fractionDigits: 2 }
        : null;

    const attributeRanges: Record<string, number[]> = {};

    allVariants.forEach((variant) => {
      variant.attributes.forEach((attr) => {
        if (
          attr.name !== 'weight' &&
          attr.name !== 'length' &&
          groupedFeatureMetadata[attr.name]?.isVariant &&
          Number.isInteger(attr.value)
        ) {
          if (!attributeRanges[attr.name]) {
            attributeRanges[attr.name] = [];
          }

          attributeRanges[attr.name].push(attr.value);
        }
      });
    });

    const attributeMinMax: string[] = Object.entries(attributeRanges).reduce(
      (acc, [key, val]) => {
        const min = Math.min(...val);
        const max = Math.max(...val);
        if (max > min) {
          acc.push(`${min} - ${max}${groupedFeatureMetadata[key]?.suffix}`);
        }

        return acc;
      },
      [] as string[],
    );

    return product.selectedVariant.key
      ? [
          ...acc,
          {
            price,
            isOnStock,
            name:
              selectedVariantName || product.name['en-GB'] || product.name.en,
            sku: product.selectedVariant.sku,
            slug: product.selectedVariant.key,
            product: product as any,
            attributesRaw: product.selectedVariant.attributes,
            attributeRanges: attributeMinMax,
            ...(fromPrice ? { fromPrice } : {}),
            ...(variantColors ? { colors: variantColors } : {}),
            photo: product.selectedVariant.images?.[0]
              ? { secure_url: product.selectedVariant.images[0]?.url }
              : null,
          },
        ]
      : acc;
  }, [] as Sproutl.ProductListing[]);
}
