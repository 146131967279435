import styled from 'styled-components';
import Section from 'components/layout/Section';
import theme from 'utils/theme';
import { mobile, tablet, desktop } from 'utils/media';
import Container from 'components/layout/Container';

export const PressBannerContainer = styled(Container)`
  background-color: ${theme.colors.beige};

  & {
    padding: 0 20px;
  }

  ${mobile} {
    & {
      padding-bottom: 40px;
    }
  }

  ${tablet} {
    & {
      padding: 0;
    }
  }
`;

export const PressBannerSection = styled(Section)`
  background-color: ${theme.colors.beige};
  width: 100%;
  max-width: 100%;
  padding: 0;

  ${tablet} {
    padding: 40px 0;
  }

  ${desktop} {
    margin: auto;
    padding: 60px 0;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 38px;
  max-width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: ${theme.pageWidth.extended}px;

  ${mobile} {
    & span {
      width: 43.333333%;
      justify-content: center;
      padding-top: 40px;

      &:last-child {
        display: none;
      }
    }
  }

  ${tablet} {
    & span {
      justify-content: center;

      &:last-child {
        display: none;
      }
    }
  }

  ${desktop} {
    & span:last-child {
      display: flex;
    }
  }
`;

export const PressBannerItem = styled.span`
  box-sizing: content-box;
  flex: 1;
  display: flex;
  font-size: 0;

  ${mobile} {
    flex: none;

    & div {
      min-height: 0;
      height: auto;
      max-height: 30px;
      width: 100%;
    }
  }

  ${tablet} {
    flex-basis: initial;
    align-items: center;
  }

  ${desktop} {
    justify-content: space-around;
    padding: initial;
  }
`;
